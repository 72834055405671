import { cssBundleHref } from '@remix-run/css-bundle';
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
'@remix-run/node';
import { json, redirect } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import * as Sentry from '@sentry/browser';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { useEffect, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide, ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { useChangeLanguage } from 'remix-i18next/react';

import { ConnectWallet } from './components/connect-wallet';
import { GoogleAnalytics } from './components/google-analytics';
import { Hotjar } from './components/hotjar';
import {
  AcceptTermsModal,
  Chatbox,
  PageLoadingIndicator } from
'./components/utils/';
import {
  Namespace,
  SUPPORTED_LOCALES,
  detectLocale,
  routeWithLocale,
  useChangePathnameLocale,
  useLocale } from
'./i18n';
import { Footer } from './sections/footer';
import { getSession } from './sessions.server';
import globalStylesheet from './styles/global.css';
import libsStylesheet from './styles/libs.css';
import { getErrorBoundaryData, withCache } from './utils';
import { useIsClient } from './utils/use-client';

import { page404Illustration } from '~/assets/images';
import { environmentVariables, isDevelopment } from '~/config';
import { navigationData } from '~/data/';
import useLocalizePathname from '~/i18n/use-localize-pathname';
import {
  Button,
  ButtonVariant,
  DataPoint,
  Hero,
  HeroImage,
  Navigation,
  ScrollArea,
  ScrollBar,
  TextL } from
'~/tenset-components';
import { useWallet } from '~/tenset-web3';

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = detectLocale(request);

  const url = new URL(request.url);
  const paths = url.pathname.split('/').slice(1);

  if (paths[0] !== locale) {
    throw redirect(`/${locale}/${paths.join('/')}`, {
      status: 301,
      headers: { 'Cache-Control': 'no-cache' }
    });
  }

  const session = await getSession(request.headers.get('Cookie'));

  const sessionWallet = {
    address: session.get('walletAddress')
  };

  return withCache(
    json({
      locale,
      env: environmentVariables,
      sessionWallet
    })
  );
}

export type RootLoader = typeof loader;

export const handle = {
  i18n: Namespace.COMMON
};

export const meta: MetaFunction = () => [
{
  title: 'Tenset'
}];


export const links: LinksFunction = () => [
...(cssBundleHref ?
[
{ rel: 'stylesheet', href: globalStylesheet },
{ rel: 'stylesheet', href: libsStylesheet },
{
  rel: 'manifest',
  href: '/site.webmanifest'
},
{
  rel: 'shortcut icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/favicons/favicon-32x32.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/favicons/favicon-16x16.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '192x192',
  href: '/favicons/android-chrome-192x192.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '256x256',
  href: '/favicons/android-chrome-256x256.png'
},
{
  rel: 'apple-touch-icon',
  type: 'image/png',
  sizes: '180x180',
  href: '/favicons/apple-touch-icon.png'
},
{
  rel: 'mask-icon',
  href: '/favicons/safari-pinned-tab.svg'
},

{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap'
},
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap'
}] :

[])];


interface DocumentProps {
  children: ReactNode;
  additionalScripts?: ReactNode;
}

function Document({ children, additionalScripts }: DocumentProps) {
  const { i18n } = useTranslation();

  const changePathnameLocale = useChangePathnameLocale();
  const locale = useLocale();

  const localeData = {
    supportedLocales: SUPPORTED_LOCALES,
    currentLocale: locale,
    changePathnameLocale,
    routeWithLocale: routeWithLocale(locale)
  };

  useChangeLanguage(locale);

  const isClient = useIsClient();
  isClient && injectStyle();

  return (
    // why antialiased? https://stackoverflow.com/a/15774226
    <html lang={locale} dir={i18n.dir()} className="antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>

      <body className="font-poppins flex min-h-screen flex-col bg-neutral-900 text-white">
        <Navigation
          data={navigationData}
          localeData={localeData}
          rightContent={<ConnectWallet />}
          bottomContent={<PageLoadingIndicator />} />


        <main className="flex-grow">{children}</main>

        <div>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="dark"
            transition={Slide} />

        </div>

        <Footer />

        {additionalScripts}

        <AcceptTermsModal />
        <ScrollRestoration />

        {!isDevelopment &&
        <>
            <Hotjar />
            <GoogleAnalytics />
          </>}


        <Scripts />
        {isDevelopment && <LiveReload />}
      </body>
    </html>);

}

function App() {
  const { sessionWallet } = useLoaderData<RootLoader>();

  const fetcher = useFetcher({ key: 'root' });

  const { wallet } = useWallet();

  useEffect(() => {
    const subscription = wallet?.changed$.subscribe(() => {
      if (!wallet.isConnected) {
        fetcher.submit(
          {},
          {
            method: 'post',
            action: '/api/wallet/disconnect'
          }
        );

        return;
      }

      if (wallet.account !== sessionWallet.address) {
        fetcher.submit(
          {},
          {
            method: 'post',
            action: `/api/wallet/connect?walletAddress=${wallet?.account}`
          }
        );
      }
    });

    return () => subscription!.unsubscribe();
  }, [sessionWallet.address]);

  useEffect(() => {
    Sentry.setUser(
      wallet?.account ?
      {
        id: wallet.account
      } :
      null
    );
  }, [wallet?.account]);

  return (
    <Document additionalScripts={<Chatbox />}>
      <Outlet />
    </Document>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  const { message, stack } = getErrorBoundaryData(error);

  const { t } = useTranslation([Namespace.COMMON]);

  const localizePathname = useLocalizePathname();

  captureRemixErrorBoundaryError(error);

  return (
    <Document>
      <section className="container place-items-center md:mb-16">
        <Hero
          title={t('error.title')}
          description={
          <ScrollArea
            type="auto"
            className="pb-2 max-w-80 sm:max-w-screen-sm lg:max-w-none">

              <TextL>{stack}</TextL>

              <ScrollBar orientation="horizontal" />
            </ScrollArea>}

          rightContent={<HeroImage src={page404Illustration} alt="Error" />}
          leftContent={
          <div className="flex flex-col gap-4">
              <DataPoint
              label="Stack"
              className="max-w-80 sm:max-w-screen-sm lg:max-w-none">

                <ScrollArea type="auto" className="pb-4">
                  {message}

                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </DataPoint>

              <div>
                <Button
                to={localizePathname('/contact')}
                variant={ButtonVariant.Primary}>

                  {t('error.action')}
                </Button>
              </div>
            </div>} />


      </section>
    </Document>);

}

export default withSentry(App, { errorBoundaryOptions: { showDialog: true } });