import { useTranslation } from 'react-i18next'

import type { MarketDataApiData } from '~/api/types'
import type { SimulatedStats } from '~/hooks/simulated-stats'
import { MARKETPLACE_BURNS_ANIMATION_DURATION } from '~/hooks/simulated-stats'
import { Namespace } from '~/i18n'
import { BuybacksDataSection } from '~/sections/homepage/buybacks/data'
import { CryptoCurrencyFormatter } from '~/tenset-components'

export interface BurnsAndMarketDataProps {
  marketDataApiData: MarketDataApiData
  simulatedStats: SimulatedStats
}

const formatter = ({ value }: { value: number }) => (
  <CryptoCurrencyFormatter value={value} currency="10SET" precision={[4, 4]} />
)

export function BurnsAndMarketData({
  marketDataApiData,
  simulatedStats,
}: BurnsAndMarketDataProps) {
  const { t } = useTranslation([Namespace.COMMON])

  const buybacksData = [
    {
      label: t('burns-and-market-data.burns.burned-today'),
      data: [
        {
          value: simulatedStats.burnedToday,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.burns.total'),
      data: [
        {
          value: simulatedStats.totalBurned,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.burns.marketplace'),
      data: [
        {
          value: simulatedStats.marketplaceBurns,
          formatter,
          animatedNumberDuration: MARKETPLACE_BURNS_ANIMATION_DURATION,
        },
      ],
    },

    {
      label: t('burns-and-market-data.burns.other'),
      data: [
        {
          value: simulatedStats.otherBurns,
          formatter,
        },
      ],
    },
  ]

  const marketData = [
    {
      label: t('burns-and-market-data.supply.total'),
      data: [
        {
          value: marketDataApiData.initialTotalSupply,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.supply.current'),
      data: [
        {
          value: simulatedStats.currentSupply,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.supply.project'),
      data: [
        {
          value: 90_548_953,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.locked.total'),
      data: [
        {
          value: marketDataApiData.locked,
          formatter,
        },
      ],
    },
    {
      label: t('burns-and-market-data.locked.current'),
      data: [
        {
          value: simulatedStats.currentCirculation - 90_548_953,
          formatter,
        },
      ],
    },
  ]

  return (
    <div className="flex flex-col gap-8 lg:gap-12">
      <BuybacksDataSection
        burnsData={buybacksData}
        title={t('burns-and-market-data.title.burns')}
      />

      <BuybacksDataSection
        burnsData={marketData}
        title={t('burns-and-market-data.title.market')}
      />
    </div>
  )
}
