import clsx from 'clsx'
import { ReactElement } from 'react'

import { Button, ButtonVariant } from '../Button'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { ScrollArea } from '../ScrollArea'
import { H3 } from '../Typo/Header'

export type CheckboxOption = Pick<CheckboxProps, 'id' | 'label' | 'children'>

export interface CheckboxGroupProps {
  title?: string | ReactElement
  name: string
  options: CheckboxOption[]
  checked: string[]
  onCheckedChange: (checked: string[]) => void
  scrollAreaClassName?: string
  className?: string
}

export function CheckboxGroup({
  title,
  name,
  options,
  checked,
  onCheckedChange,
  scrollAreaClassName,
  className,
}: CheckboxGroupProps) {
  const areAllChecked = checked.length === options.length

  function flipCheck() {
    onCheckedChange(areAllChecked ? [] : options.map(({ id }) => id))
  }

  return (
    <div className={clsx(className, 'flex flex-col gap-2')}>
      <div className="flex flex-wrap gap-2 justify-between">
        {title && (typeof title === 'string' ? <H3>{title}</H3> : title)}

        <Button
          variant={ButtonVariant.Secondary}
          usePadding={false}
          className="px-1"
          onClick={flipCheck}
        >
          {areAllChecked ? 'Deselect all' : 'Select all'}
        </Button>
      </div>

      <ScrollArea
        className={clsx(
          'max-h-48 has-[div[data-state=visible]]:pr-2',
          scrollAreaClassName
        )}
        type="auto"
      >
        <div className="flex flex-col gap-2">
          {options.map(({ id, label, children }) => (
            <Checkbox
              key={id}
              id={id}
              name={name}
              label={label}
              checked={checked.includes(id)}
              onCheckedChange={isChecked =>
                onCheckedChange(
                  isChecked
                    ? [...checked, id]
                    : checked.filter(checkedId => checkedId !== id)
                )
              }
            >
              {children}
            </Checkbox>
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}
